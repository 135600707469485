body {
    padding-top: 40px;
    padding-bottom: 40px;
}
.modal-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #337ab7;
}
.modal-header h4 {
    margin:0;
    color:#fff;
}
.modal-header img {
    float: left;
    margin-right: 20px;
}
.form-signin {
    max-width: 400px;
    margin: 0 auto;
    background: #fff;
}
p.link a {
    font-size: 11px;
}
.form-signin .inner {
    padding: 20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.form-signin .checkbox {
    font-weight: normal;
}
.form-signin .form-control {
    margin-bottom: 17px !important;
}
.form-signin .form-control:focus {
    z-index: 2;
}

.modal-header a:link,
.modal-header a:visited,
.modal-header a:hover,
.modal-header a:active {
    text-decoration: none;
    color: white;
}

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 9px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #000 !important;
    background: #f9f9f9 !important;
    background-image: none;
    border: 1px solid #dfe0e1;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}